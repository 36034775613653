@use 'styles/energy-heading' as *;

.Content {
    grid-area: body;
    align-self: center;
    display: flex;
    flex-direction: column;
    [data-type='Magazines'] & {
        flex-direction: row;
        @media (max-width: 600px) {
            flex-direction: column;
        }
        @media (min-width: 600px) {
            width: 100vw;
            padding-right: var(--page-padding-horizontal) !important;
        }
    }
    [data-type='Full_Width'] & {
        width: 100vw;
        padding: 0 !important;
        [data-site='main'] & {
            height: 90%;
            justify-content: flex-end !important;
            margin-top: var(--spacing-small) !important;
        }
    }
    justify-content: center;
    gap: var(--spacing-large);
    position: relative;
    z-index: 3;
    @media ( max-width: 699px) {
        margin: var(--spacing-medium) 0 0 0 !important;
        padding: 0 var(--page-padding-horizontal);
        [data-type='MEE_Half_Image'] & { margin: var(--spacing-large) 0 0 0 !important;}
        [data-site='events'] & {
            margin: var(--spacing-medium) 0 !important;
        }
    }
    @media ( min-width: 700px ) {
        padding: 0 var(--spacing-small) 0 var(--page-padding-horizontal);
        [data-type='Magazines'] & {
            padding: 0 0 0 var(--page-padding-horizontal);
        }
        margin-top: var(--spacing-large);
        margin-bottom: var(--spacing-large);
        [data-site='events'] [data-height='Short'] & {
            margin-top: var(--spacing-medium) !important;
            margin-bottom: var(--spacing-medium) !important;
        }
    }
    &:empty {
        display: none;
    }
    [data-type='MEE_Half_Image'] & {
        height: 200%;
        width: min-content;
    }
}

.BodyContent {

    position: relative;
    z-index: 3;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--colour-text-tertiary);
    font-size: var(--font-size-medium);

    [data-site='main'] & {
        text-shadow:
            0 0 1px black,
            0 0 2px black,
            0 0 4px black,
            0 0 6px black;
    }

    [data-type='MEE_Half_Image'] & { 
        width: min-content;
    }

    [data-type='Magazines'] & {
        @media ( max-width: 600px) {
            text-align: center;
        }
        h1 {
            font-weight: bold;
        }
    }

    [data-type='Full_Width'] & {
        width: 90%;
        [data-site='events'] & {
            text-align: center;
        }
        [data-site='main'] & {
            margin-left: var(--page-padding-horizontal);
        }
    }

    @media ( max-width: 699px ) {
        > section {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
        }
    }

    > section {
        padding: 0 !important;
        background: transparent !important;
    }

    h1 {
        font-size: var(--font-size-hero);
        margin: var(--spacing-small-half) 0 0 0;
        font-weight: normal;
        [data-site='events'] & { 
            font-weight: bold; 
            text-align: center;
            margin: var(--spacing-small-half) var(--spacing-small-half) 0 0;
        }
        color: var(--colour-text-secondary);
        margin-bottom: var(--spacing-medium);
        [data-type='MEE_Half_Image'] & {
            @include energy-heading;
            --colour-text-accent-primary: #{$mee-colour-5} !important;
            white-space: nowrap;
            @media ( max-width: 350px ) {
                white-space: normal;
            }
        }
    }

    p {
        margin-top: 0.5em;
        font-size: var(--font-size-large);
    }

    > ul {
        padding-bottom: var(--spacing-medium);
        > li {
            list-style-type: none !important;
            > a { margin-left: 0 !important; }
        }
    }

    > section {
        padding-bottom: 0 !important;
        > :last-child {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    > * {
        --background: transparent !important;
        &:nth-child(even) { --background: transparent !important; }
    }
    > section {
        padding-bottom: var(--spacing-large);
    }
    > ul > li > a {
        --colour-link: var(--colour-text-secondary);
        --colour-link-active: var(--colour-text-accent-primary);
    }
}

.HeroAreaImage {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    min-width: 100vw;
    height: 100%;
    min-height: 100%;
    object-fit: cover;
    [data-has-content='false'] & {
        position: relative !important;
        z-index: 3 !important;
        margin: 0 !important;
        background: var(--colour-insight-background) !important;
    }
    [data-type='MEE_Half_Image'] & {
        position: relative;
        min-width: fit-content;
        border-bottom-left-radius: calc(2*var(--spacing-medium-three-quarters));
        > img {
            border-bottom-left-radius: calc(2*var(--spacing-medium-three-quarters));
        }
        @media( max-width: 600px ) {
            display: none;
        }
    }
}

.MenuLinksList {
    align-self: flex-start;
    position: relative;
    z-index: 3;
    width: 66%;
    [data-type='Full_Width'] & {
        width: 100%;
    }
    text-shadow: 0 0 1px black, 0 0 2px black, 0 0 4px black, 0 0 6px black;
    padding: 0 var(--spacing-large) 0 0;
    --colour-link: var(--colour-text-accent-primary);
    --colour-link-active: var(--colour-text-secondary);
    [data-type="Magazines"] & {
        text-shadow: none;
        align-self: center;
        display: flex;
        justify-content: flex-end;
        @media ( max-width: 600px) {
            justify-content: center;
        }
        --colour-link-active: var(--colour-text-primary);
        padding: 0;
    }
    h1 { display: none; }
    ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: var(--spacing-small);
        margin-top: 0;
        li {
            backdrop-filter: blur(4px);
        }
        a {
            color: var(--colour-link);
            &:hover { color: var(--colour-link-active) !important; }
            
        }
        [data-type='Magazines'] & {
            margin: 0;
            a {
                background: white;
                border: none;
                padding: 13px;
            }
        }
        [data-type='Full_Width'] & {
            justify-content: center;
            [data-site='main'] & {
                @media ( max-width: 700px ) {
                    justify-content: flex-start;
                    margin-left: var(--page-padding-horizontal);
                }
            }
        }
    }
}
